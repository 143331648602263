import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/img/hodlie/hodlie_logo.png';
import logoPro from 'assets/img/hodlie/explorer.png';
import logoProPlus from 'assets/img/hodlie/investor.png';
import logoTrader from 'assets/img/hodlie/hodlie_trader_logo.png';
import { firstProTier, secondProTier, traderProTier } from 'staticData/common';
import Lottie from 'lottie-react';
import xmasHat from 'assets/img/animated-icons/xmasHat.json';
import { breakpoints } from 'helpers/utils';

const Logo = ({
  at,
  width,
  height = false,
  className,
  proTier = 0,
  showAnimation = false,
  ...rest
}) => {
  var isTrader = false;
  var isProPlus = false;
  var isPro = false;
  if (proTier >= traderProTier) isTrader = true;
  else if (proTier >= secondProTier) isProPlus = true;
  else if (proTier === firstProTier) isPro = true;

  // Animation date
  const animationStartDate = new Date('2023-12-22T18:00:00');
  const animationEndDate = new Date('2023-12-29T00:00:00');
  const currentDate = new Date();
  const isTimeToAnimate =
    currentDate >= animationStartDate && currentDate <= animationEndDate;

  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
        style={{ position: 'relative' }}
      >
        <img
          className="me-2"
          src={
            isTrader
              ? logoTrader
              : isProPlus
              ? logoProPlus
              : isPro
              ? logoPro
              : logo
          }
          alt="Logo"
          height={height}
          width={height ? false : width}
        />
        {showAnimation && isTimeToAnimate && (
          <Lottie
            animationData={xmasHat}
            loop={true}
            style={{
              position: 'absolute',
              top:
                window.innerWidth <= breakpoints['md']
                  ? !isTrader
                    ? '-15px'
                    : '-10px'
                  : !isTrader && !isPro
                  ? '-10px'
                  : 0,
              left: !isTrader ? '-10px' : 0,
              width: '15%',
              transform: 'scaleX(-1)'
            }}
          />
        )}
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  proTier: PropTypes.number,
  showAnimation: PropTypes.bool
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
