import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import SearchBox from './SearchBox';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import autoCompleteInitialItem from 'data/autocomplete/autocomplete';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router';
import 'assets/scss/custom/navbar.scss';
import { showEnvAsProduction } from 'staticData/common';

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig,
    userInfos
  } = useContext(AppContext);
  // const envType = process.env.REACT_APP_ENV;

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  const getNavbarStyle = () => {
    // const isLocalOrDev = envType !== 'PROD';
    const isLocalOrDev = false;
    const shouldNotShowAsProduction = !showEnvAsProduction;

    if (isLocalOrDev && shouldNotShowAsProduction) {
      return {
        backgroundColor: 'var(--falcon-secondary)',
        borderRadius: '10px'
      };
    }

    return {};
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={classNames('navbar-custom fs--1 navbar-top sticky-kit', {
        '': showDropShadow && !isChat
      })}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
      // Style to have different navbar color in LOCAL and DEV
      style={getNavbarStyle()}
    >
      <div style={{ position: 'relative' }}>
        <Navbar.Toggle
          className={classNames('toggle-icon-wrapper me-md-3 me-2', {
            'd-lg-none': navbarPosition === 'top',
            [`d-${navbarBreakPoint}-none`]:
              navbarPosition === 'vertical' || navbarPosition === 'combo'
          })}
          as="div"
        >
          <button
            className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
            onClick={handleBurgerMenu}
            id="burgerMenu"
          >
            <span className="navbar-toggle-icon">
              <span className="toggle-line" />
            </span>
          </button>
        </Navbar.Toggle>
      </div>

      <Logo
        at="navbar-top"
        height={32}
        id="topLogo"
        proTier={userInfos.pro_tier}
        showAnimation
      />

      {navbarPosition === 'top' || navbarPosition === 'combo' ? (
        <Navbar.Collapse
          in={navbarCollapsed}
          className="scrollbar pb-3 pb-lg-0"
        >
          <Nav navbar>
            <NavbarTopDropDownMenus />
          </Nav>
        </Navbar.Collapse>
      ) : (
        <Nav
          navbar
          className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
          as="ul"
        >
          <Nav.Item as="li">
            <SearchBox autoCompleteItem={autoCompleteInitialItem} />
          </Nav.Item>
        </Nav>
      )}

      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
