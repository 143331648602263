import React, { useContext } from 'react';
import AppContext from 'context/Context';
import { Footer as textLang } from 'staticData/languages';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';
import { genSign } from 'helpers/utils';
import 'assets/scss/custom/footer.scss';

const Footer = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  return (
    <footer className="footer footer-custom">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            {textLang.reservedRight[lang] + ' '}
            <span className="d-none d-sm-inline-block">| </span>
            <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
            <a href="https://hodlie.ai">Hodlie</a>
            {' | '}
            <a
              href="https://www.iubenda.com/privacy-policy/20555626"
              target="__blank"
            >
              privacy policy
            </a>
            {' - '}
            <a
              href="https://www.iubenda.com/privacy-policy/20555626/cookie-policy"
              target="__blank"
            >
              cookie policy
            </a>
          </p>
        </Col>
        <span
          className="d-none"
          dangerouslySetInnerHTML={{ __html: genSign() }}
        />
        <Col sm="auto">
          <p className="mb-0 text-600">
            {version + ' | '}
            <a href="/termsandconditions">{textLang.tos[lang]}</a>
            {' | '}
            <a href="/termsofuse">{textLang.tou[lang]}</a>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
