import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import { accountRoutes, appRoutes } from 'router/routes';
import NavbarDropdownApp from './NavbarDropdownApp';

const NavbarTopDropDownMenus = () => {
  // const {
  //   config: { navbarCollapsed, showBurgerMenu },
  //   setConfig
  // } = useContext(AppContext);

  // const handleDropdownItemClick = () => {
  //   if (navbarCollapsed) {
  //     setConfig('navbarCollapsed', !navbarCollapsed);
  //   }
  //   if (showBurgerMenu) {
  //     setConfig('showBurgerMenu', !showBurgerMenu);
  //   }
  // };
  return (
    <>
      <NavbarDropdown>
        <NavbarDropdownApp items={accountRoutes.children} />
      </NavbarDropdown>

      <NavbarDropdown>
        <NavbarDropdownApp items={appRoutes.children} />
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
