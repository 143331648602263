import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/hodlie/comingsoon.png';
import SoftBadge from 'components/common/SoftBadge';
import { ComingSoon as textLang } from 'staticData/languages';
import Lottie from 'lottie-react';
import AppContext from 'context/Context';
import 'assets/scss/custom/comingSoon.scss';

const ComingSoon = ({
  media = editing,
  imgCol = 6,
  imgColxs = 12,
  useImg = true,
  badge = { show: true, text: 'Coming Soon', colorClass: 'success' },
  text = textLang.working,
  button = { show: true, text: textLang.dashboardLink, link: '/' },
  h100 = false,
  lottieClass = '',
  cardClass = ''
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  return (
    <Card
      style={{ backgroundColor: 'var(--falcon-body-bg)', boxShadow: 'none' }}
      className={cardClass + ' ' + (h100 ? 'h-100' : '')}
    >
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={imgCol} xs={imgColxs}>
            {useImg ? (
              <img src={media} className="img-fluid" alt="" />
            ) : (
              <Lottie
                animationData={media}
                loop={true}
                style={{ display: 'contents' }}
                className={lottieClass}
              />
            )}
          </Col>
          <Col
            lg={12 - imgCol}
            xs={imgColxs === 12 ? 12 : 12 - imgColxs}
            className="ps-lg-4 my-5 text-center text-lg-start"
          >
            {badge.show && (
              <SoftBadge pill bg={badge.colorClass} className="me-2">
                {badge.text}
              </SoftBadge>
            )}
            <h3 className="mt-1">
              {typeof text === 'string' ? text : text[lang]}
            </h3>
            {button.show && (
              <Button
                variant={button.variant ? button.variant : 'falcon-primary'}
                size={button.size ? button.size : ''}
                as={Link}
                to={button.link}
              >
                {typeof button.text === 'string'
                  ? button.text
                  : button.text[lang]}
              </Button>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ComingSoon.propTypes = {
  media: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imgCol: PropTypes.number,
  imgColxs: PropTypes.number,
  useImg: PropTypes.bool,
  badge: PropTypes.object,
  text: PropTypes.string,
  button: PropTypes.object,
  h100: PropTypes.bool,
  lottieClass: PropTypes.string,
  cardClass: PropTypes.string
};

export default ComingSoon;
