import React, { useContext } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { languages, flags } from 'staticData/languages';
import AppContext from 'context/Context';

const LanguageDropdown = () => {
  const {
    config: { lang },
    setConfig
  } = useContext(AppContext);

  const handleChangeLang = language => {
    setConfig('lang', language);
  };

  return (
    <Dropdown navbar={true}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        style={{
          height: '30px',
          background: 'transparent',
          borderColor: 'transparent',
          boxShadow: 'none'
        }}
        className="pe-1 ps-0 nav-link d-flex align-items-center"
      >
        <Image src={flags[lang]} />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-menu-card  dropdown-menu-end"
        style={{ minWidth: '3rem', right: '-0.75rem' }}
      >
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {languages.map(
            (language, index) =>
              language !== lang && (
                <Dropdown.Item
                  key={index}
                  className="fw-bold text-warning"
                  style={{ opacity: '1' }}
                  onClick={() => handleChangeLang(language)}
                >
                  <Image src={flags[language]} />
                </Dropdown.Item>
              )
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
