import React, { useContext } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import LanguageDropdown from 'components/navbar/top/LanguageDropdown';
import AppContext from 'context/Context';
import { TopNavRightSideNavItem as textLang } from 'staticData/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import 'assets/scss/custom/botState.scss';
// import { firstProTier , goldColor } from 'staticData/common';
// import { trackEvent } from 'services/mixpanel';

const TopNavRightSideNavItem = () => {
  const {
    config: { isRTL, lang },
    deferredPrompt,
    downloadApp,
    loggedIn
  } = useContext(AppContext);

  // const handleTrackEvent = () => {
  //   trackEvent('Go Pro', {
  //     type: 'Navbar Top Mobile'
  //   });
  // };

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <span className="d-none d-sm-block">
        <LanguageDropdown />
      </span>
      {/* <Link
        onClick={() => handleTrackEvent()}
        to="/pricing"
        className={
          'binance-button me-1 rounded-md ' +
          (loggedIn && userInfos.pro_tier < firstProTier
            ? 'd-block d-xl-none'
            : 'd-none')
        }
        style={{
          marginTop: '2px',
          padding: '0px',
          width: '100%',
          height: '88%',
          borderRadius: '15px'
        }}
      >
        <div className="ps-1 pe-1">
          <FontAwesomeIcon
            icon={'crown'}
            style={{ marginBottom: '1px', height: '13px' }}
            className="me-1"
            color={goldColor}
          />
          <font style={{ fontSize: '12px' }} color={goldColor}>
            {textLang.goPro[lang]}
          </font>
        </div>
      </Link> */}

      {loggedIn && <ProfileDropdown icon={'user'} />}
      {!loggedIn && (
        <Nav.Link as={Link} to="/login">
          Login
        </Nav.Link>
      )}
      <Nav.Link
        className="ps-0 px-2 theme-control-toggle"
        onClick={() => downloadApp()}
        style={{ display: deferredPrompt !== null ? 'block' : 'none' }}
      >
        <OverlayTrigger
          key="left"
          placement={isRTL ? 'bottom' : 'left'}
          overlay={<Tooltip id="ThemeColor">{textLang.download[lang]}</Tooltip>}
        >
          <div className="">
            <FontAwesomeIcon icon={'download'} className="fs-0" />
          </div>
        </OverlayTrigger>
      </Nav.Link>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
