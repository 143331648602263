import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginForm as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Flex from 'components/common/Flex';
import { useHistory, useLocation } from 'react-router-dom';
import { gtm_event_userRegistered } from 'services/externals/google/googleTagManager';
import { hs_sendMailAddress } from 'services/externals/hubspot';
import { identifyMixpanelUser, trackEvent } from 'services/externals/mixpanel';
import { toltSignup } from 'services/externals/tolt';
import 'assets/scss/custom/login.scss';

const LoginForm = ({ hasLabel, getInfo }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const location = useLocation();
  const fullPath = location.pathname + location.search;

  // State
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const routerHistory = useHistory();

  const handleSubmit = async e => {
    e.preventDefault();

    // Generate Recaptcha Token & Login
    window.grecaptcha.enterprise.ready(async () => {
      const recaptcha_token = await window.grecaptcha.enterprise.execute(
        '6LcE9igoAAAAAJ2plIdhi4bvhKr3hn95kyNmxTqc',
        { action: 'login' }
      );

      let form = new FormData();
      form.append('username', formData['username'].toLowerCase());
      form.append('password', formData['password']);
      form.append('recaptcha_token', recaptcha_token);
      let loginResponse = await endpointInterface(
        lang,
        'backend',
        'loginPage',
        'post',
        true,
        form
      );
      if (loginResponse.validResponse) {
        // Send mail address to hubspot
        hs_sendMailAddress(
          loginResponse.data?.email || formData['username'].toLowerCase(),
          fullPath
        );
        // Identify user in mixpanel
        identifyMixpanelUser(
          loginResponse.data?.tracking_id || 0,
          loginResponse.data?.email || formData['username']
        );
        await getInfo();
        routerHistory.push('/');
      } else {
        toast.error(loginResponse.responseMessage, { closeButton: false });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    });
  };

  const sendGoogleToken = async response => {
    let token = response['credential'];
    let form = new FormData();
    form.append('token', token);
    form.append('tolt_referral', window.tolt_referral || '');
    let loginResponse = await endpointInterface(
      lang,
      'backend',
      'googleLogin',
      'post',
      true,
      form
    );
    let userWasRegistered = false;
    if (
      loginResponse.data.already_registered != undefined &&
      loginResponse.data.already_registered === true
    )
      userWasRegistered = true;
    if (loginResponse.validResponse) {
      let data = await getInfo(true);
      // Send mail address to hubspot
      hs_sendMailAddress(loginResponse.data?.email || '', fullPath);
      if (!userWasRegistered) {
        // Send the user_registered GTM event
        gtm_event_userRegistered(data?.tracking_id || null);
        // send event in mixpanel
        trackEvent('signup', {
          method: 'google',
          mail: loginResponse.data?.email || formData.email
        });
        // send registration data to tolt
        toltSignup(loginResponse.data?.email || formData.email);
      }
      // identify user in mixpanel
      identifyMixpanelUser(
        loginResponse.data?.tracking_id || 0,
        loginResponse.data?.email || formData.email
      );
      routerHistory.push('/');
    } else {
      toast.error(loginResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // TODO: Get google client id from config file

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{textLang.email[lang]}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? textLang.email[lang] : ''}
          value={formData.username}
          name="username"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>
      <Flex className="justify-content-center">
        <Link className="fs--1 mb-0" to={`/forgot-password`}>
          {textLang.forgotPwd[lang]}
        </Link>
      </Flex>
      <Form.Group>
        <Flex className="justify-content-center">
          <Button
            type="submit"
            color="primary"
            className="mt-3 custom-button"
            disabled={!formData.username || !formData.password}
          >
            {textLang.login[lang]}
          </Button>
        </Flex>
      </Form.Group>
      <Flex className="justify-content-center mt-3 mb-3">
        {textLang.or[lang]}
      </Flex>
      <div className="d-flex justify-content-center">
        <GoogleOAuthProvider clientId="963417905135-ho3o0v3h0lurjmgp9kgkdqfunai4k1nm.apps.googleusercontent.com">
          <GoogleLogin
            text="signin_with"
            onSuccess={sendGoogleToken}
            onError={error => toast.error(error, { closeButton: false })}
            width="250px"
            useOneTap
          />
        </GoogleOAuthProvider>
      </div>
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool,
  getInfo: PropTypes.func
};

export default LoginForm;
