import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'context/Context';
import { NavbarVerticalMenuItem as textLang } from 'staticData/languages';
import { traderProTier } from 'staticData/common';
library.add(faBullhorn);

const NavbarVerticalMenuItem = ({ route, userInfos }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const dynamicBadges = {
    Wallet: {
      text:
        Object.keys(userInfos).length > 0
          ? userInfos.connected_exchanges &&
            userInfos.connected_exchanges.includes('binance')
            ? textLang.connected[lang]
            : textLang.notConnected[lang]
          : '',
      type:
        Object.keys(userInfos).length > 0
          ? userInfos.connected_exchanges &&
            userInfos.connected_exchanges.includes('binance')
            ? 'success'
            : 'danger'
          : ''
    }
  };

  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon
            icon={route.icon}
            style={{ color: route.color ? route.color : '' }}
          />
        </span>
      )}
      <span
        className="nav-link-text ps-1"
        style={{ color: route.color ? route.color : '' }}
        onClick={route.onclick ? () => route.onclick() : null}
      >
        {route.specialText && userInfos.pro_tier < traderProTier
          ? typeof route.specialText === 'object' && route.specialText !== null
            ? route.specialText[lang]
            : route.specialText
          : typeof route.name === 'object' && route.name !== null
          ? route.name[lang]
          : route.name}
      </span>
      {route.target === '_blank' && (
        <span className="nav-link-icon ms-2">
          <FontAwesomeIcon icon={'external-link-alt'} />
        </span>
      )}
      {route.badge && (
        <SoftBadge
          pill
          bg={
            route.badge.dynamic && Object.keys(userInfos).length > 0
              ? dynamicBadges[route.name].type
              : route.badge.type
          }
          className={route.target === '_blank' ? '' : 'ms-2'}
          // style={{ lineHeight: 'unset' }}
        >
          {route.badge.dynamic && Object.keys(userInfos).length > 0
            ? dynamicBadges[route.name].text
            : typeof route.badge.text === 'object' && route.badge.text !== null
            ? route.badge.text[lang]
            : route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
  userInfos: PropTypes.object
};

export default React.memo(NavbarVerticalMenuItem);
