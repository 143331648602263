import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Spinner, Form } from 'react-bootstrap';
import gifLogo from 'assets/img/hodlie/gifLogo.gif';
import gifLogoDarkMode from 'assets/img/hodlie/gifLogoDarkMode.gif';
import AppContext from 'context/Context';

const LoadingSpinner = ({
  subText,
  useGif = false,
  className = 'mt-3 mb-6'
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  return (
    <>
      <span className={className}>
        <Col
          md={6}
          className="gx-1"
          style={{ margin: '0 auto', textAlign: 'center' }}
        >
          {useGif ? (
            <img
              className="me-2"
              src={isDark ? gifLogoDarkMode : gifLogo}
              alt=""
              width="200"
            />
          ) : (
            <Spinner animation="border" role="status"></Spinner>
          )}
          <div>
            <Form.Label>{subText ? subText : null}</Form.Label>
          </div>
        </Col>
      </span>
    </>
  );
};

LoadingSpinner.propTypes = {
  subText: PropTypes.string,
  className: PropTypes.string,
  useGif: PropTypes.bool
};

export default LoadingSpinner;
